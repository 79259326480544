.mainSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7rem 0 0 0;
}

.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95%;
  flex-wrap: wrap;
  min-height: 90vh;
}

.contactBody {
  width: 35rem;
}

.contactTitle {
  margin: 0 0 1.5rem 2rem;
}


.Logo {
  width: 20rem;
  max-width: 80%;
  margin: 4rem;
}

.links {
  text-decoration: none;
}

@media (max-width: 900px) {
  .Logo {

    margin: 4rem 0rem;
  }

}