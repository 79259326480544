.mainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 100vh;
    padding: 7rem 0 0 0;
}

.image {
    width: 30rem;
    max-width: 60%;
}

.footerContainer{
    position: absolute;
    width: 100%;
    bottom: 0;
}