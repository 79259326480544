.mainSection {
    width: 20rem;
    max-width: 80%;
    margin: 1rem;
   background-color: white;
    padding: 2rem;
    filter: drop-shadow(9px 9px 9px rgba(54, 54, 54, 0.3));
}

.title {
    height: 3rem;
}

.Items {
    margin: 2rem 0;
    height: 7rem;
}

.text {
    margin: 0.5rem 0;
}