.MainSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem;
}

.icon {
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin: 1rem;
}

.text {
  padding-left: 1rem;
  border-left: 1px var(--C-Black) solid;
  min-height: 3rem;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}




@media (max-width: 900px) {
  
    
  .text {
    font-size: 1rem;
  
  } 
    
    }