.SolutionBullet {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    height: 2rem;
  }
  
  .SolutionIcon {
    max-width: 2rem;
    max-height: 2rem;
  }

  .SolutionBulletTitle {
    margin: 0 1rem;
  }
  
  .SolutionBulletText {
    border-left:1px var(--C-Black) solid;
    padding-left: 1rem;
  }
  
  
