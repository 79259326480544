.mainSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* /////////////////      INTRO       ////////////////// */
.Title {
  margin: 2rem 0;
}

.Text {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.bullet {
  background-color: var(--C-Red);
  width: 10px;
  height: 10px;
  margin-right: 1rem;
}

.IntroSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12rem 0 4rem 0;
}
.IntroContainer {
  width: 80rem;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.IntroText {
  width: 40rem;
  padding: 0rem;
  max-width: 100%;
}

.IntroImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IntroImage {
  width: 25rem;
  max-width: 100%;
}

/* ////////////////  BANDEAU  ////////////// */
.BandeauSection {
  width: 100%;
  background-color: var(--C-Grey);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BandeauContainer {
  width: 80rem;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.BandeauItem {
  /*   color: white; */
  text-align: center;
  min-width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.BandeauIcon {
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin-bottom: 1rem;
}

/* /////////////  SOLUTION  //////////////// */


.SolutionSection {
  width: 90rem;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 7rem 3rem;
}

.IntroButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
}

.separator {
  width: 30rem;
  max-width: 70%;
  height: 1px;
  background-color: white;
  margin: 2rem 0;
}

.TopFooterSection {
  width: 100%;
  background-color: var(--C-Red);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0 3rem 0;
}

.FooterSection {
  width: 100%;
  height: 3rem;
  background-color: var(--C-Red);
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterTitle {
  color: white;
  width: 50rem;
  max-width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.FooterButton {
  margin: 0 2rem;
  text-decoration: none;
  color: white;
}

.FooterItems {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.FooterContacts {
  flex-wrap: wrap;
}


.FooterIcon {
  max-width: 2rem;
  max-height: 2rem;
  margin: 0rem 1rem 0 2rem;
}

.FooterInfo {
  color: white;
}
