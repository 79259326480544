.mainContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--C-Red);
  /*   background-color: rgb(255, 255, 255, 0.8);*/
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px; 
  color: white;
  z-index: 20;
}

.mainContainerScrolled {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--C-Red);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  color: white;
  z-index: 100;
  opacity: 0.65;
}

.mainContainerScrolled:hover {
  opacity: 1;
}

.logo {
    max-height: 3.5rem;
    max-width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoScrolled {
  max-height: 2.8rem;
  max-width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LogoLink {
  width: 15rem;
  color: white;
}

.navLink {
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  border-bottom: 2px transparent solid;
  transition: all 0.2s ease-in-out;
}

.navLink:hover {
  height: 100%;
  color: white;
  padding: 1rem;
  border-bottom: 2px white solid;
}

.navLinkActive {
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--C-Red);
  background-color: white;
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 2px white solid;
}

/* ////////////// BURGER ////////////*/

.burgerIcon {
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .burgerIcon:hover {
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(10%);
  
}
 */

.burgerIcon span {
  width: 2rem;
  height: 2px;
  background-color: white;
  margin: 0.3rem 0;
}
/* .burgerIcon span:hover {
  width: 2rem;
  height: 3px;
  background-color: white;
 margin: 0.3rem 0;
} */

.burgerOpenIcon  {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
 /*  background-color: white; */
}

.burgerCrossContainer {
  cursor: pointer;
  /* background-color: white; */
}


.burgerCross {
  max-width: 2rem;
  filter: invert();

}
/* 
.burgerCross2 {
  width: 2rem;
  height: 2px;
  background-color: white;
  transform: rotate(-45deg);
  transition: all 0.7s ease-in-out;
}
 */
.burgerContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--C-Red);
  /*   background-color: rgb(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px; */
  color: white;
  z-index: 20;
  transition: all 1s ease-in-out;
}

.burgerContainerScrolled {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--C-Red);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  color: white;
  z-index: 100;
  transition: all 1s ease-in-out;
}

.burgerNavContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.burgerNavContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.burgerLink {
  margin: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
  font-size: 1rem;
  border-bottom: 2px transparent solid;
  transition: all 0.2s ease-in-out;
  color: white;
}

.burgerLink:hover {
  height: 5rem;
  color: var(--C-Light);
  margin: 1rem;
  border-bottom: 2px white solid;
}

.burgerLinkActive {
  height: 5rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--C-Light);
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 2px white solid;
}
