.mainSection {
  max-width: 90%;
  display: flex;
  margin: 3rem 0;
  padding: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textSection {
  width: 30rem;
  max-width: 90%;
  height: 100%;
  margin: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.title {
  margin: 1rem 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  border-left: 1px var(--C-Black) solid;
  padding-left: 2rem;
}
.textWhite {
  color: white;
}

.icon {
  max-width: 5.5rem;
  max-height: 4.5rem;
  margin-right: 2rem;
}

.priceTag {
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  background-color: var(--C-Black);
  color: white;
  border-radius: 5px;
}

.priceTagWhite {
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  background-color: white;
  color: var(--C-Black);
  border-radius: 5px;
}

.imageSection {
  width: fit-content;
  max-width: 100%;
  margin: 0 1rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 20rem;
}

.ButtonItems {
  text-decoration: none;
}

@media (max-width: 900px) {
  
  .textContainer {
    display: flex;
    flex-direction: column;
    border-left: none;
    padding-left: 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

}