.FooterSection {
  width: 100%;
  height: 3rem;
  background-color: var(--C-Red);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FooterButton {
  min-width: fit-content;
  margin: 0 0.7rem;
  text-decoration: none;
  color: white;
}

.FooterContacts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterIcon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin: 0rem 1rem;
}

.FooterLinks {
  width: 100%;
  width: fit-content;
  margin-right: 1rem;
}

.FooterInfo {
  color: white;
}

@media (max-width: 900px) {
  .FooterLinks {
    width: 100%;
    width: fit-content;
    margin-right: .5rem;
  }
}
