.mainSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .BannerSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12rem 0 5rem 0;
    background-color: var(--C-Red);
  }
  
  .BannerImage {
    width: 40rem;
    max-width: 90%;
    filter: drop-shadow(9px 9px 9px rgba(54, 54, 54, 0.3));
  }

  .HowToSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12rem 0 1rem 0;
  }

  .HowToContainer {
    max-width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
  }

  .LineContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem 0 1rem;
  }

  .Line {
    width: 1rem;
    height: 30rem;
    max-height: 100%;
    background-color: var(--C-Red);
   position: absolute;
  }

.LineLast {
    width: 1rem;
    height: 15rem;
    max-height: 100%;
    background-color: var(--C-Red);
   position: absolute;
   transform: translateY(-7rem);
  }

  .Number {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: white;
    border: 1rem var(--C-Red) solid;
    padding: 1rem;
    z-index: 10;
    font-size: 2.453rem;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .TextContainer {
    width: 35rem;
  }

  .Title {
    margin-bottom: 0.5rem;
  }


  .RdvSection {
    width: 100%;
    margin: 5rem 0 10rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .RdvTitle {
    text-align: center;
    max-width: 90%; 
  }
  .ButtonContact {
    width: fit-content;
    padding: 1rem;
    background-color: var(--C-Red);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 1rem;
    padding: 1rem 2rem;
  }

  
.FooterSection {
    width: 100%;
    height: 3rem;
    background-color: var(--C-Red);
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  
  .FooterButton {
    margin: 0 2rem;
    text-decoration: none;
    color: white;
  }
  
  .FooterContacts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
  }
  
  .FooterIcon {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin: 0rem 0.7rem;
  }
  
  .FooterInfo {
    color: white;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    color: white;
  }


  @media (max-width: 900px) {
  
    .Title {
     font-size: 1.2rem;
    }

    .Body {
      font-size: 1rem;
     }
}