.mainSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.BannerSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12rem 0 5rem 0;
  background-color: var(--C-Red);
}

.BannerImage {
  width: 40rem;
  max-width: 90%;
  filter: drop-shadow(9px 9px 9px rgba(54, 54, 54, 0.3));
}

.CandidatureSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7rem 0 5rem 0;
  padding-top: 12rem;
}

.CandidatureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.CandidatureText {
  max-width: 27rem;
  padding: 1rem;
  margin: 2rem;

}

.CandidatureTitre {
  margin-bottom: 1rem;
}

.CandidatureBody {
  margin-bottom: 1rem;
}

.CandidatureButton {
  width: fit-content;
  background-color: var(--C-Red);
  padding: 1rem 2rem;
  margin: 2rem 0;
  color: white;
}

.CandidatureImage {
  max-width: 20rem;
  max-height: 20rem;
  margin: 2rem;
}

.JobSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 7rem 0;
}

.JobTitle {
    text-align: center;
    margin-bottom: 3rem;
}

.jobContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.JobItems {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}


.FooterSection {
    width: 100%;
    height: 3rem;
    background-color: var(--C-Red);
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  
  .FooterButton {
    margin: 0 2rem;
    text-decoration: none;
    color: white;
  }
  
  .FooterContacts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
  }
  
  .FooterIcon {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin: 0rem 0.7rem;
  }
  
  .FooterInfo {
    color: white;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    color: white;
  }


  @media (max-width: 900px) {
  
    
.CandidatureTitre {
  font-size: 2rem;

} 
  
  }