.mainSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Title {
  margin: 2rem 0;
}


.SubTitle {
  margin: 2rem 0;
  font-size: 1rem;
}


.Text {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: left;

}

.bullet {
  background-color: var(--C-Red);
  width: 10px;
  height: 10px;
  margin-right: 1rem;
}


/* /////////////////      INTRO       ////////////////// */
.IntroSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12rem 0 5rem 0;
}
.IntroContainer {
  width: fit-content;
  max-width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.IntroText {
  width: 30rem;
  padding: 2rem;
  max-width: 95%;
}

.IntroImageContainer {
  width: 25rem;
  max-width: 80%; 
  display: flex;
  align-items: center;
  justify-content: center;

}

.IntroImage {
  width: 20rem;
  max-width: 80%;
}

/* /////////////////       MADAGASCAR       ////////////////// */

.MadagascarSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 9rem 0;
}

.MadaContainer {
  width: fit-content;
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
}

.MadaText {
  width: 30rem;
  max-width: 90%;
}

.MadaIcon {
  max-width: 3rem;
  max-height: 3rem;
  margin: 0.5rem 1.5rem 0.5rem 0;
}

.MadaImage {
  width: 18rem;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MadaImageContainer {
  width: 22rem;
  max-width: 80%;
}

/* /////////////////       VALEURS       ////////////////// */

.ValeursSection {
  background-color: var(--C-Grey);
  width: 100%;
  padding: 6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ValeursTitle {
  margin: 4rem 1rem;
  text-align: center;
}

.ValeursItems {
  max-width: 60rem;
  /* max-width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* /////////////////      QUALITE       ////////////////// */
.QualitySection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7rem 0;
}
.QualityContainer {
  width: 60rem;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.QualityTitleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.QualityText {
  width: 20rem;
  padding: 2rem 2rem 2rem 0;
  max-width: 80%;
}

.QualityItem {
  padding: 1rem 0;
}

.QualityImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.QualityImage {
  width: 30rem;
  max-width: 90%;
}

.QualityTitle {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 1rem 0;
}

.QualityCercle {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-left: 1rem;
}

/* /////////////////      SECURITE       ////////////////// */
.SecuriteSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7rem 0 10rem 0;
}
.SecuriteContainer {
  width: 80rem;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.SecuriteText {
  width: 40rem;
  padding: 2rem;
  max-width: 80%;
}

.SecuriteImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SecuriteImage {
  width: 20rem;
  max-width: 80%;
}

/* /////////////////      RSE       ////////////////// */
.RseSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0 7rem 0;
}

.RseTextContainer {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
}
.RseSubTitle {
  text-align: center;
  width: 40rem;
  max-width: 70%;
}

.RseBody {
  width: 80rem;
  max-width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.RseItem {
  width: 28rem;
  padding: 2rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RseTitle {
  margin-bottom: 0.5rem;
}

.RseIcon {
  padding: 0 1rem 0 0;
  width: 5rem;
  
}

.RseIconContainer {
  width: 6rem;
}

.RseText {
  padding-left: 2rem;
  border-left: 1px var(--C-Black) solid;
}

.RseImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.RseImage {
  width: 20rem;
  max-width: 80%;
}

/* /////////////////       FOOTER       ////////////////// */

.FooterSection {
  width: 100%;
  height: 3rem;
  background-color: var(--C-Red);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FooterButton {
  margin: 0 2rem;
  text-decoration: none;
  color: white;
}

.FooterContacts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterIcon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin: 0rem 0.7rem 0 2rem;
}

.FooterInfo {
  color: white;
}






@media (max-width: 900px) {
  
  .Title, .ValeursTitle {
   font-size: 2.5rem;
  }
  


}