@font-face {
  font-family: 'Montserrat';
  font-weight: 100 900;
  src: local('Montserrat'), url("./Fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  font-family: "Montserrat";
  font-weight: 400;
  color: #3b3838;
  margin: 0;
  padding: 0;
  --C-One: #d7d7d7;
  --C-Two: #b5171b;
  --C-Two1: hsl(41, 98%, 61%);
  --C-Light: #ffffff;
  --C-Mid: #f1f1f1;
  --C-Mid2: #c0c0c0;
  --C-Dark: hwb(0 0% 90%);
  --C-DarkBLack: #3b3838;
  --C-MidBlack: #494545;
  --C-Red: #c1040a;
  --C-Red-Dark: hsl(358, 96%, 29%);
  --C-Grey: #f2f2f2;
  --C-Black: #3b3838;
}

h1 {
  font-size: 3.052rem;
  font-weight: 900;
  text-transform: uppercase;
}

h2 {
  font-size: 1.953rem;
  font-weight: 600;
  text-transform: uppercase;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--C-Dark) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--C-Dark);
  border-radius: 0px;
  border: 0px solid transparent;
}
