.mainSection {
  max-width: 90%;
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem;
  padding: 1rem;
}

.imageCT {
  height: 4rem;
}

.image {
  max-width: 6rem;
  max-height: 6rem;
}

.title {
  margin: 0 0 1rem 0;
}

.text {
    text-align: justify;
    max-width: fit-content;
}


@media (max-width: 900px) {
  
  .mainSection {
   font-size: 1.2rem;
   width: 20rem;
  }

}