.mainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.introSection {
  min-height: 100vh;
  height: fit-content;
  padding: 7rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--C-Red);
}

.introTitle {
  color: white;
  text-transform: uppercase;
  width: 50rem;
  /* max-width: 80%; */
  font-size: 4.5rem;
}

.introTitleMoving {
  color: white;
  text-transform: uppercase;
  width: 50rem;
  font-size: 4.5rem;
  /* max-width: 80%;  */
}
.IntroArrowContainer{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
  position: absolute;
  bottom: 2rem;
  margin: auto;
  text-transform: uppercase;
}
.IntroArrowContainer p {
  color: white;
  font-weight: 500;
}
.IntroArrow {
  width: 3rem;
  filter: invert();
  animation: blinker 1s ease-in-out infinite;
  margin: 1rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.WhoSection {
  width: 100%;
  padding: 7rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.WhoImageContainer {
  width: 25rem;
  max-width: 80%; 
  display: flex;
  align-items: center;
  justify-content: center;

}

.WhoImage {
  width: 20rem;
  max-width: 80%;
}


.AvantageSection {
  background-color: var(--C-Grey);
  width: 100%;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AvantageTitle {
  margin: 3rem 1rem;
}

.AvantageItems {
  max-width: 90%;
  width: 90rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.SolutionSection {
  width: 100%;
  padding: 7rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.SolutionItems {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /*   margin: 1rem; */
}

.SolutionTextContainer {
  /*   width: 35rem; */
  width: 35rem;
  /* max-width: 80%; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 2rem;
}
.SolutionImage {
  max-width: 40%;
}

.SolutionTitle {
  margin: 1rem 0;
}

.SolutionText {
  margin-bottom: 2rem;
}

.SolutionButton {
  margin: 2rem 0;
}

.BulletContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.BulletNumber{
  background-color: var(--C-Red);
  width: 2rem;
  height: 2rem;
  margin: 0 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.SolutionImageContainer {
  width: 25rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterSection {
  width: 100%;
  height: 3rem;
  background-color: var(--C-Red);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FooterButton {
  margin: 0 2rem;
  text-decoration: none;
  color: white;
}

.FooterContacts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterIcon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin: 0rem 0.7rem 0 2rem;
}

.FooterInfo {
  color: white;
}

@media (max-width: 900px) {
  .SolutionItems {
    max-width: 90%;
  }

  .introTitle {
    font-size: 2.1rem;
    width: fit-content;
    width: 19.2rem;
  }

  .introTitleMoving {
    font-size: 2.1rem;
    width: fit-content;
    width: 19.2rem;
  }
}

/* 
BUTTON */


.button {
  align-items: center;
  background-color: var(--C-Red);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 1rem auto;
  text-decoration: none;
}

.button:hover,
.button:focus { 
  background-color: var(--C-Red-Dark);
  color: #ffffff;
}

.button:active {
  background: var(--C-Red-Dark);
  color: rgb(255, 255, 255, .7);
}

.button:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}
