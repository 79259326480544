.mainSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7rem 0 0 0;
}

.MLContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  flex-wrap: wrap;
  max-width: 90%;
}

.MLBody {
  width: 35rem;
  max-width: 80%;
  margin: 2rem;
}

.MainTitle {
  padding-bottom: 3rem;
  max-width: 90%;
}

.title {
  padding-bottom: 0.7rem;
  max-width: 90%;
}
.text {
  padding-bottom: 2rem;
  text-align: justify;

}


.Logo {
  width: 20rem;
  max-width: 80%;
  margin: 4rem;
}

.footerContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media (max-width: 900px) {
  .Logo {

    margin: 4rem 0rem;
  }

}