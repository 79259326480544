.mainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: black;
}

.construction {
  position: absolute;
  bottom: 0;
  max-height: 40%;
  max-width: 80%;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 7rem;
}

.inputBox {
  margin: 1rem 0;
  width: 5rem;
}

.form {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: 900;
  max-width: 30%;
  min-width: 20rem;
  margin: 0 3rem;
  color: white;
}

.contactTitre {
  color: white;
  font-family: "Montserrat";
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-top: 30vh;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Montserrat";
  font-weight: 900;
}


.contactText {
  color: white;
}
